<template>
  <div class="match-content">
    <march-title title="选购产品" more :checked="true">
      <div class="dp_f goods-list">
        <template v-for="(item,index) in tableData" :key="item.goodsId">
          <shop-goods-info :goods-info="item" @change-count="(val)=>changCount(index,val)"></shop-goods-info>
        </template>
      </div>
    </march-title>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref, watch} from "vue";
import ShopGoodsInfo from "@views/shop/components/goodsInfo/index.vue";
import MarchTitle from './title.vue'
import {shopGoodsList} from '@utils/api/shop.js'

const {proxy} = getCurrentInstance();
const tableData = ref([])
const getGoodsList = async () => {
  let res = await shopGoodsList()
  if (res.code !== 0) proxy.$message.error(res.msg)
  tableData.value = res.data.list || []
}
const changCount = (index, val) => {
  tableData.value[index].count = val
}
getGoodsList()
defineExpose({
  tableData
})
watch(tableData, () => {
  proxy.$emit('changeObj')
}, {deep: true})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "matchContent",
});
</script>

<style lang="scss" scoped>
.goods-list {
  flex-wrap: wrap;
}
</style>
